<template>
  <v-dialog
    value="true"
    persistent
    transition="dialog-bottom-transition"
    :width="dialogWidth"
    >
    <v-card>
      <v-card-text class="pa-2">
        <VueCropper
          ref='cropper'
          v-bind="cropperProps"
        />
      </v-card-text>
      <v-card-actions>
        <v-btn color="default" @click="$emit('close')">Закрыть</v-btn>
        <v-spacer></v-spacer>
        <v-btn @click="done" color="success">Сохранить</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import VueCropper from 'vue-cropperjs'
import 'cropperjs/dist/cropper.css';

export default {
  components: { VueCropper },
  props: {
    file: {
      type: File,
      default: null
    },
    cropperProps: {
      type: Object,
      default: () => (null)
    },
    dialogWidth: {
      type: String,
      default: '400px'
    },
  },
  methods: {
    readFile() {
      if(!this.file.type.includes('image/')) {
        alert('Выбранный файл не является изображением')
        return
      }
      if (typeof FileReader !== 'function') {
        alert('Ваш браузер не проддерживает FileReader API')
        return
      }
      const reader = new FileReader()
      reader.onload = (event) => {
        this.$refs.cropper.replace(event.target.result)
      }
      reader.readAsDataURL(this.file)
    },
    done() {
      this.$refs.cropper.getCroppedCanvas().toBlob(blob => {
        this.$emit('done', { image: blob })
      })
    }
  },
  mounted() {
    this.readFile()
  }
}
</script>
