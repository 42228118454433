<template>
  <v-card width="50%" style="margin: 15px auto;">
    <v-form v-model="profileValid">
      <v-tabs
        background-color="primary"
        dark centered icons-and-text
        >
        <v-tab>
          Профиль
          <v-icon>mdi-account</v-icon>
        </v-tab>
        <v-tab>
          Контакты
          <v-icon>mdi-phone</v-icon>
        </v-tab>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text class="pt-0">
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="12">
                      <v-text-field
                        label="Название организации"
                        v-model="profile.title"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="ИНН"
                        v-model="profile.inn"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                    <v-col cols="12">
                      <v-text-field
                        label="КПП"
                        v-model="profile.kpp"
                        :rules="[rules.required]"
                        >
                      </v-text-field>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="6">
                  <AvatarField v-model="profile.image" icon="mdi-factory"/>
                </v-col>
                <v-col cols="12">
                  <v-text-field
                    v-model="profile.email"
                    label="E-mail"
                    :rules="[rules.required]"
                    persistent-hint
                    hint="Вы можете создать новый ящик на @weldassisit.ru"
                  >
                    <template v-slot:append>
                      <v-btn
                        :class="{ info: createEmail }"
                        depressed
                        tile
                        style="margin-top: -4px; margin-left: -4px; height: 32px;"
                        @click="createWeldassisMailbox"
                      >
                        @weldassist.ru
                      </v-btn>
                    </template>
                  </v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea
                    label="Коротко о организации"
                    rows="2"
                    v-model="profile.about"
                    >
                  </v-textarea>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
        <v-tab-item eager>
          <v-card flat tile>
            <v-card-text>
              <v-row>
                <v-col cols="2">
                  <v-text-field
                    label="Индекс"
                    v-model="profile.zip"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Страна"
                    v-model="profile.country"
                  />
                </v-col>
                <v-col cols="4">
                  <v-text-field
                    label="Регион"
                    v-model="profile.region"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Обл."
                    v-model="profile.state"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Город"
                    v-model="profile.city"
                  />
                </v-col>
                <v-col cols="7">
                  <v-text-field
                    label="Улица"
                    v-model="profile.street"
                  />
                </v-col>
                <v-col cols="2">
                  <v-text-field
                    label="Дом"
                    v-model="profile.building"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Строенние"
                    v-model="profile.housing"
                  />
                </v-col>
                <v-col cols="3">
                  <v-text-field
                    label="Оффис"
                    v-model="profile.office"
                  />
                </v-col>
              </v-row>
              <v-row>
                <v-col cols="6">
                  <v-text-field
                    label="Рабочий телефон"
                    v-model="profile.workPhone"
                  />
                </v-col>
                <v-col cols="6">
                  <v-text-field
                    label="Мобильный телефон"
                    v-model="profile.mobile"
                  />
                </v-col>
                <v-col cols=8>
                  <v-text-field
                    label="Сайт"
                    v-model="profile.site"
                  />
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs>
    </v-form>
    <v-card-actions>
      <v-btn color="default" @click="$router.go(-1)">Назад</v-btn>
      <v-spacer></v-spacer>
      <v-btn color="success" :loading="loading"
        :disabled="!profileValid || loading" @click="create"
        >
        Создать
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
import rules from '@/libs/validate-rules.js'
import AvatarField from '@/components/AvatarField.vue'

export default {
  components: { AvatarField },
  data: () => ({
    tab: null,
    profileValid: false,
    rules: rules,
    createEmail: false,
    profile: {
      title: '',
      inn: '',
      kpp: '',
      logo: '',
      about: '',
      country: '',
      city: '',
      zip: '',
      region: '',
      street: '',
      building: '',
      housing: '',
      office: '',
      mobilePhone: '',
      workPhone: '',
      email: '',
      site: '',
    },
  }),
  computed: { loading() { return !!this.$store.state.apiRequestCounter } },
  methods: {
    createWeldassisMailbox() {
      if (!this.profile.email) {
        alert('Необходимо ввсети хотя бы название почты')
      }
      if (this.createEmail) {
        this.createEmail = false
        this.profile.email = ''
        return
      }
      let [account, domain] = this.profile.email.split('@')
      let email = account + '@weldassist.ru'
      this.axios.post('postfix/check-email', { email: email }).then(r => {
        if (!r.data.exist) {
          this.profile.email = email
          this.createEmail = true
        }
      })
    },
    create() {
      this.$axios.post('organization/profile/create', {
        ...this.profile,
        createEmail: this.createEmail
      }).then(r => {
        // this.$store.dispatch('setAlert', { message: r.data.message, type: 'info' })
        this.$store.commit('user/addOrgId', r.data.id)
        this.$router.push('/user/organization/index')
      })
    }
  }
}
</script>
